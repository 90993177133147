<template>
  <section v-if="showFrom">
    <div class="mt-2">{{ $t("FROM") }}</div>
    <b-form-select
      @change="ChangedSelectedFrom"
      name="sefos_message_select_from"
      class="mt-2"
      v-model="selected_from"
      :options="from_list"
    ></b-form-select>
  </section>
</template>
<script>
export default {
  props: {
    sdkMessage: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    faxMessage: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    value: Object,
  },
  data() {
    return {
      loa_level: this.value,
      from_list: [],
      selected_from: {
        functionbox_uuid: this.functionbox_uuid,
        user_uuid: this.user_uuid,
      },
    };
  },
  methods: {
    ChangedSelectedFrom()
    {
      this.$emit("input", this.selected_from);
      this.$emit("change");
    },
    async GenerateFromList() {
      if(!this.sdkMessage && !this.faxMessage){
        this.from_list.push({
          value: {
            functionbox_uuid: "",
            user_uuid: "",
          },
          text: this.user.information.name + ", " +  this.user.information.email + ""
        });
      }
      if (this.showFrom) {
        if (this.user.functionboxes.items != undefined) {
          for (let ix = 0; ix < this.user.functionboxes.items.length; ix++) {
            let text = "";
            let add = false;
            if(!this.sdkMessage && !this.faxMessage)
            {
              add = true;
              text = this.user.functionboxes.items[ix].name +
                  ", " +
                  this.user.functionboxes.items[ix].email +
                  "";
            }
            if(this.sdkMessage && this.user.functionboxes.items[ix].sdk_enabled)
            {
              add = true;
              text = this.user.functionboxes.items[ix].name +
                  ", " +
                  this.user.functionboxes.items[ix].sdk_identifier +
                  "";
            }
            if(this.faxMessage && this.user.functionboxes.items[ix].fax_enabled)
            {
              add = true;
              text = this.user.functionboxes.items[ix].name +
                  ", " +
                  this.user.functionboxes.items[ix].fax_identifier +
                  "";
            }
            if(add)
            {
              let item = {
                functionbox_uuid:
                  this.user.functionboxes.items[ix].functionbox_uuid,
                user_uuid: "",
              };
              this.from_list.push({
                value: item,
                text: text,
              });
              if (
                this.functionbox_uuid ==
                this.user.functionboxes.items[ix].functionbox_uuid
              ) {
                this.selected_from = {
                  functionbox_uuid:
                    this.user.functionboxes.items[ix].functionbox_uuid,
                  user_uuid: "",
                };
              }
            }
          }
        }
        if(!this.sdkMessage && !this.faxMessage){
          if (this.user.delegatedboxes.items != undefined) {
            for (let ix = 0; ix < this.user.delegatedboxes.items.length; ix++) {
              let item = {
                functionbox_uuid: "",
                user_uuid: this.user.delegatedboxes.items[ix].user_uuid,
              };
              this.from_list.push({
                value: item,
                text:
                  this.user.delegatedboxes.items[ix].name +
                  ", " +
                  this.user.delegatedboxes.items[ix].email +
                  "",
              });
              if (
                this.user_uuid == this.user.delegatedboxes.items[ix].user_uuid
              ) {
                this.selected_from = {
                  functionbox_uuid: "",
                  user_uuid: this.user.delegatedboxes.items[ix].user_uuid,
                };
              }
            }
          }
        }
      }
    }
  },
  computed: {
    showFrom: function () {
      if (this.user.delegatedboxes.items != undefined) {
        if (this.user.functionboxes.items != undefined) {
          return (
            (this.user.functionboxes.items.length != 0 ||
              this.user.delegatedboxes.items.length != 0)
          );
        }
      }
      return "";
    }
  },
  watch: {
    value(loa_level) {
      this.loa_level = loa_level;
    },
  },
  mounted() {
    this.GenerateFromList();
  },
};
</script>
<style>
</style>