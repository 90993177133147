<template>
  <span
    ><slot></slot>
    <b-sidebar
      sidebar-class="big_sidebar create-fax-message"
      v-model="show"
      right
      :title="GetTitle"
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      @shown="shownSidebar"
      no-close-on-esc
      @hidden="closeBtn"
      v-if="CreateFaxMessageDTO"
    >
      <template #footer="{ hide }">
        <div class="ml-2">
          <b-button 
          :disabled="saving"
          @click="closeBtn">{{ $t("CLOSE") }}</b-button>

          <b-button
            @click="saveMessage(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span><span v-if="saving">{{$t("SAVING")}}..</span></b-button
          >

          <b-button
            :disabled="disabledSendMessage"
            @click="saveMessage(false)"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            v-if="CreateFaxMessageDTO.message_uuid != ''"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
      </template>

      <b-overlay :show="!CreateFaxMessageDTO.isDraft && saving" rounded="sm">
        <div v-if="loaded" class="view-content create-message px-3 py-2">
          <SelectFrom
            fax-message
              v-if="CreateFaxMessageDTO.response_to_message_uuid == ''"
            v-model="selected_from"
            :functionbox_uuid="CreateFaxMessageDTO.functionbox_uuid"
            :user_uuid="CreateFaxMessageDTO.user_uuid"
          ></SelectFrom>

          <div class="mt-2">{{ $t("TO") }}</div>
          
          <SearchRecipients
            message_type="fax-message"
            :user_uuid="user_uuid"
            :key="'forceRedrawRecipientsKey_' + forceRedrawRecipientsIndex"
            :functionbox_uuid="functionbox_uuid"
            :isNumber="true"
            :disabled="isReply"
            :singleSelect="true"
            v-model="addresses"
            @IsSearching="IsSearching"
            @forceRedraw="forceRedrawRecipientsIndex++"
          ></SearchRecipients>

          <div class="mt-2">{{ $t("INFORMATION") }}</div>

          <InputBody
            v-model="CreateFaxMessageDTO.body"
            hide-description
            @valid="(n) => (validatedBody = n)"
            :limit="20000"
          ></InputBody>

          <div class="mt-2">{{ $t("ADD_FILES") }}</div>

          <div :key="'AddFiles' + CreateFaxMessageDTO.message_uuid">
            <InputFiles
              :message_uuid="CreateFaxMessageDTO.message_uuid"
              :functionbox_uuid="CreateFaxMessageDTO.functionbox_uuid"
              :user_uuid="CreateFaxMessageDTO.user_uuid"
              v-model="CreateFaxMessageDTO.attachments"
              :max-size="15"
              fileType=".pdf,.doc,.docx"
              @valid="(n) => (validatedFiles = n)"
              total-size
              :cannot-upload="$t('DRAFT.FAX_UPLOAD')"
            ></InputFiles>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
import SearchRecipients from "../SearchRecipients";
import InputBody from "../../Input/Body";
import InputFiles from "../../Input/Files";
import SelectFrom from "../SelectFrom";
export default {
  components: {
    InputBody,
    SearchRecipients,
    InputFiles,
    SelectFrom,
  },
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    isReply: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isComplete: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isForward: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isReplyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    attachments: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      sending: false,
      validatedBody: true,
      validatedFiles: true,
      isSearching: false,
      addresses: [],
      forceRedrawRecipientsIndex: 0,
      selected_from: {
        functionbox_uuid: this.functionbox_uuid,
        user_uuid: this.user_uuid,
      },
      loaded: false,
      saving: false,
      timeout: 2000,
      folder_uuid: "",
      canSave: false,
      changedContent: false,
      CreateFaxMessageDTO: {
        to_number: "",
        user_uuid: this.user_uuid,
        functionbox_uuid: this.functionbox_uuid,
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 3,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
        },
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
      },
      step: 1,
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      methods: [],
      loading: false,
      fields: [
        {
          thStyle: "width: 100%;",
          key: "authentication_identifier",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          thStyle: "width: 45%;",
          key: "email",
          class: "hidden-mobile",
          label: this.$t("EMAIL"),
          sortable: false,
        },
        {
          thStyle: "width: 45%;",
          key: "authentication_method",
          class: "hidden-mobile",
          label: this.$t("AUTH_METHODS.VERIFY"),
          sortable: false,
        },
        {
          thStyle: "width: 10%;",
          key: "language",
          class: "hidden-mobile",
          label: this.$t("LANGUAGE.TITLE"),
          sortable: false,
        },
      ],
      settings: null,
      timer: null,
    };
  },
  methods: {
    IsSearching(isSearching) {
      this.isSearching = isSearching;
    },
    debounceSave() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage(true);
      }, this.timeout);
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      if (
        this.isReply ||
        this.isForward ||
        this.isReplyAll ||
        this.isComplete
      ) {
        await this.InitMessage();
      } else {
        if (this.message_uuid != "") {
          await this.GetInformation();
        } else {
          this.InitSettings();
        }
      }
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.CreateFaxMessageDTO.action = "NEW";
    },
    async InitMessage() {
      if (this.isReply) {
        this.CreateFaxMessageDTO.action = "REPLY";
      } else if (this.isReplyAll) {
        this.CreateFaxMessageDTO.action = "REPLYALL";
      } else if (this.isForward) {
        this.CreateFaxMessageDTO.action = "FORWARD";
      } else if (this.isComplete) {
        this.CreateFaxMessageDTO.action = "COMPLETE";
      }
      try {
        let response = await this.$http.post(this.user.hostname + "/fax/init/secure", this.CreateFaxMessageDTO);
        this.CreateFaxMessageDTO = response.data;
        this.addresses = [];
        if (response.data.to_number != "" && !this.isForward) {
          this.addresses.push(response.data.to_number);
        }
        this.InitDone();
      } catch {
        console.log("Could not fetch InitMessage");
      }
    },
    async GetNewAttachments(message_uuid) {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: message_uuid,
            functionbox_uuid: this.CreateFaxMessageDTO.functionbox_uuid,
            user_uuid: this.CreateFaxMessageDTO.user_uuid,
          }
        );
        this.CreateFaxMessageDTO.attachments =
          result.data.createFaxMessageDTO.attachments;
      } catch {
        console.log("Could not fetch GetNewAttachments");
      }
    },
    async GetInformation() {
      try {
        let response = await this.$http
          .post(this.user.hostname + "/message/uuid/open", {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.CreateFaxMessageDTO.functionbox_uuid,
            user_uuid: this.CreateFaxMessageDTO.user_uuid,
          });
        this.CreateFaxMessageDTO = response.data.createFaxMessageDTO;
        this.addresses = [];
        if (response.data.createFaxMessageDTO.to_number != "") {
          this.addresses.push(response.data.createFaxMessageDTO.to_number);
        }
        this.InitDone();
      } catch {
        console.log("Could not GetInformation");
      }        
    },
    async InitSettings() {
      this.CreateFaxMessageDTO.attachments = this.attachments;
      if (this.currentSignature != "") {
        this.CreateFaxMessageDTO.body =
          "\n\n----------------------------\n" + this.currentSignature;
      }
      this.InitDone();
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/fax/secure/remove", {
                message_uuid: self.CreateFaxMessageDTO.message_uuid,
                user_uuid: self.user_uuid,
                functionbox_uuid: self.functionbox_uuid,
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    async saveMessage(isDraft) {
      if (this.saving || this.disabledSave) {
        return;
      }
      clearTimeout(this.timer);
      this.loading = true;
      this.saving = true;
      this.CreateFaxMessageDTO.isDraft = isDraft;
      try {
        let result = await this.$http.post(
          this.user.hostname + "/fax/secure",
          this.CreateFaxMessageDTO
        );
        this.loading = false;
        if (isDraft) {
          this.saving = false;
          if (this.CreateFaxMessageDTO.message_uuid == "") {
            let checkNotUploaded =
              this.CreateFaxMessageDTO.attachments.filter(function (item) {
                return item.status == "NOT_UPLOADED";
              }).length != 0;
            if (this.attachments.length != 0 || checkNotUploaded) {
              await this.GetNewAttachments(result.data.message_uuid);
              this.CreateFaxMessageDTO.message_uuid = result.data.message_uuid;
            } else {
              this.CreateFaxMessageDTO.message_uuid = result.data.message_uuid;
            }
          }
        } else {
          this.CreateFaxMessageDTO.message_uuid = result.data.message_uuid;
          this.$noty.info(this.$t("SENT_MESSAGE"));
          this.$emit("SentMessage");
          this.$emit("ChangeShow", false);
        }
      } catch (error) {
        this.saving = false;
        this.loading = false;
        clearTimeout(this.timer);
      }
    },
  },
  computed: {
    currentSignature() {
      let self = this;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == self.functionbox_uuid
        );
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == self.user_uuid
          );
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
    disabledSaveMessage: function () {
      if (this.CreateFaxMessageDTO.body.length > 20000) {
        return true;
      }
      if (!this.loaded) {
        return true;
      }
      if (this.saving) {
        return true;
      }
      if (!this.ValidateFileSize) {
        return true;
      }
      return false;
    },
    disabledSendMessage: function () {
      if (this.CreateFaxMessageDTO.attachments == 0) {
        if (this.CreateFaxMessageDTO.body.length == "") {
          return true;
        }
      }
      if (this.CreateFaxMessageDTO.body.length > 20000) {
        return true;
      }
      if (this.CreateFaxMessageDTO.to_number == "") {
        return true;
      }
      if (!this.loaded) {
        return true;
      }
      if (this.saving) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    },
    GetTitle() {
      if (this.saving) {
        if (this.CreateFaxMessageDTO.isDraft) {
          return (
            this.$t("MESSAGES.TYPE.fax-message") +
            " (" +
            this.$t("SAVING") +
            " .. )"
          );
        } else {
          return (
            this.$t("MESSAGES.TYPE.fax-message") +
            " (" +
            this.$t("SENDING") +
            " .. )"
          );
        }
      }
      return this.$t("MESSAGES.TYPE.fax-message");
    },
    disabledSave() {
      return !this.validatedBody || !this.validatedFiles;
    },
  },
  watch: {
    "CreateFaxMessageDTO.body": function () {
      if (this.CreateFaxMessageDTO.action != "INIT" && this.canSave) {
        console.log("CreateFaxMessageDTO.body");
        this.debounceSave();
      }
    },
    "CreateFaxMessageDTO.to_number": function () {
      if (this.CreateFaxMessageDTO.action != "INIT" && this.canSave) {
        console.log("CreateFaxMessageDTO.body");
        this.debounceSave();
      }
    },
    addresses: function () {
      if (this.addresses.length != 0) {
        this.CreateFaxMessageDTO.to_number = this.addresses[0];
      } else {
        this.CreateFaxMessageDTO.to_number = "";
      }
    },
  },
  mounted() {},
};
</script>