var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t("default"),
      _vm.CreateFaxMessageDTO
        ? _c(
            "b-sidebar",
            {
              attrs: {
                "sidebar-class": "big_sidebar create-fax-message",
                right: "",
                title: _vm.GetTitle,
                backdrop: "",
                "no-slide": "",
                lazy: "",
                shadow: "",
                "no-header-close": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { shown: _vm.shownSidebar, hidden: _vm.closeBtn },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function ({ hide }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { disabled: _vm.saving },
                                on: { click: _vm.closeBtn },
                              },
                              [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.saving || _vm.disabledSave,
                                  variant: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(true)
                                  },
                                },
                              },
                              [
                                !_vm.saving
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("SAVE_DRAFT"))),
                                    ])
                                  : _vm._e(),
                                _vm.saving
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("SAVING")) + ".."),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  disabled: _vm.disabledSendMessage,
                                  variant: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveMessage(false)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                            ),
                            _vm.CreateFaxMessageDTO.message_uuid != ""
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill float-right",
                                    attrs: {
                                      disabled: _vm.saving,
                                      variant: "danger",
                                    },
                                    on: { click: _vm.RemoveDraft },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3191406738
              ),
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: !_vm.CreateFaxMessageDTO.isDraft && _vm.saving,
                    rounded: "sm",
                  },
                },
                [
                  _vm.loaded
                    ? _c(
                        "div",
                        {
                          staticClass: "view-content create-message px-3 py-2",
                        },
                        [
                          _vm.CreateFaxMessageDTO.response_to_message_uuid == ""
                            ? _c("SelectFrom", {
                                attrs: {
                                  "fax-message": "",
                                  functionbox_uuid:
                                    _vm.CreateFaxMessageDTO.functionbox_uuid,
                                  user_uuid: _vm.CreateFaxMessageDTO.user_uuid,
                                },
                                model: {
                                  value: _vm.selected_from,
                                  callback: function ($$v) {
                                    _vm.selected_from = $$v
                                  },
                                  expression: "selected_from",
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("TO"))),
                          ]),
                          _c("SearchRecipients", {
                            key:
                              "forceRedrawRecipientsKey_" +
                              _vm.forceRedrawRecipientsIndex,
                            attrs: {
                              message_type: "fax-message",
                              user_uuid: _vm.user_uuid,
                              functionbox_uuid: _vm.functionbox_uuid,
                              isNumber: true,
                              disabled: _vm.isReply,
                              singleSelect: true,
                            },
                            on: {
                              IsSearching: _vm.IsSearching,
                              forceRedraw: function ($event) {
                                _vm.forceRedrawRecipientsIndex++
                              },
                            },
                            model: {
                              value: _vm.addresses,
                              callback: function ($$v) {
                                _vm.addresses = $$v
                              },
                              expression: "addresses",
                            },
                          }),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                          ]),
                          _c("InputBody", {
                            attrs: { "hide-description": "", limit: 20000 },
                            on: { valid: (n) => (_vm.validatedBody = n) },
                            model: {
                              value: _vm.CreateFaxMessageDTO.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.CreateFaxMessageDTO, "body", $$v)
                              },
                              expression: "CreateFaxMessageDTO.body",
                            },
                          }),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("ADD_FILES"))),
                          ]),
                          _c(
                            "div",
                            {
                              key:
                                "AddFiles" +
                                _vm.CreateFaxMessageDTO.message_uuid,
                            },
                            [
                              _c("InputFiles", {
                                attrs: {
                                  message_uuid:
                                    _vm.CreateFaxMessageDTO.message_uuid,
                                  functionbox_uuid:
                                    _vm.CreateFaxMessageDTO.functionbox_uuid,
                                  user_uuid: _vm.CreateFaxMessageDTO.user_uuid,
                                  "max-size": 15,
                                  fileType: ".pdf,.doc,.docx",
                                  "total-size": "",
                                  "cannot-upload": _vm.$t("DRAFT.FAX_UPLOAD"),
                                },
                                on: { valid: (n) => (_vm.validatedFiles = n) },
                                model: {
                                  value: _vm.CreateFaxMessageDTO.attachments,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.CreateFaxMessageDTO,
                                      "attachments",
                                      $$v
                                    )
                                  },
                                  expression: "CreateFaxMessageDTO.attachments",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }