var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings
    ? _c("section", [
        _c("div", { staticClass: "d-flex mt-2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAddParticipant,
                  expression: "showAddParticipant",
                },
              ],
            },
            [
              _c("AddressBook", {
                attrs: {
                  list: _vm.addresses,
                  user_uuid: _vm.user_uuid,
                  message_type: _vm.message_type,
                  functionbox_uuid: _vm.functionbox_uuid,
                  "single-select": _vm.singleSelect,
                  "is-number": _vm.isNumber,
                  "is-compose": "",
                },
                on: { close: _vm.closedAddressbook, selected: _vm.selected },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ onClick }) {
                        return [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover:title",
                                  value: _vm.$t("ADDRESSBOOK.TITLE"),
                                  expression: "$t('ADDRESSBOOK.TITLE')",
                                  modifiers: { "hover:title": true },
                                },
                              ],
                              staticClass: "no-border",
                              on: { click: onClick },
                            },
                            [_c("i", { staticClass: "fal fa-address-book" })]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2224782461
                ),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex-grow-1" }, [
            _c(
              "div",
              {
                staticClass: "form-control-border w-100",
                class: {
                  "is-focus": _vm.isFocus,
                  "is-invalid": !_vm.validComponent,
                  "is-valid": _vm.validComponent,
                },
              },
              [
                _vm.selectedItems.length != 0
                  ? _c(
                      "div",
                      {
                        key: _vm.selectedItems.length,
                        staticClass: "mb-2 p-1",
                      },
                      _vm._l(_vm.selectedItems, function (option, index) {
                        return _c(
                          "b-form-tag",
                          {
                            key: "tagKey_" + index,
                            staticClass: "tag-selected",
                            attrs: { tag: "span", disabled: _vm.disabled },
                            on: {
                              remove: function ($event) {
                                return _vm.removeSelected(index)
                              },
                            },
                          },
                          [
                            _c("ContactInformation", {
                              attrs: {
                                "is-meeting": _vm.IsMeeting(_vm.message_type),
                                item: option,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.showAddParticipant
                  ? _c("b-form-input", {
                      ref: "searchText",
                      staticClass: "no-border",
                      attrs: {
                        trim: "",
                        type: "text",
                        autocomplete: "off",
                        disabled: _vm.disabled,
                        placeholder: _vm.currentPlaceholder,
                        debounce: "500",
                      },
                      on: {
                        focus: _vm.focus,
                        update: _vm.onSearch,
                        click: function ($event) {
                          $event.preventDefault()
                          if ($event.target !== $event.currentTarget)
                            return null
                        },
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            )
                              return null
                            return _vm.autocompleteDown.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ])
                            )
                              return null
                            return _vm.autocompleteUp.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.eventAdd.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "tab",
                                9,
                                $event.key,
                                "Tab"
                              )
                            )
                              return null
                            return _vm.eventAdd.apply(null, arguments)
                          },
                        ],
                      },
                      nativeOn: {
                        blur: function ($event) {
                          return _vm.blurAdd.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.autocompleteResult.length != 0 &&
                          !_vm.autocompleteLoading &&
                          _vm.isFocus,
                        expression:
                          "autocompleteResult.length != 0 && !autocompleteLoading && isFocus",
                      },
                    ],
                    staticClass: "autocomplete",
                  },
                  [
                    _c(
                      "b-list-group",
                      {
                        staticClass: "autocomplete-results",
                        attrs: { flush: "" },
                      },
                      [
                        _vm._l(
                          _vm.autocompleteResultWithPaging,
                          function (option, i) {
                            return _c(
                              "b-list-group-item",
                              {
                                key: i,
                                staticClass: "clickable",
                                attrs: {
                                  active: _vm.selected_autocomplete == i,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.selectFromAutocomplete(option, i)
                                  },
                                },
                              },
                              [
                                option.item_type == "message-fax"
                                  ? _c("div", [
                                      _c("i", { staticClass: "fal fa-phone" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(option.email) +
                                          "\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  false
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                option.item_type == "addressbook"
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "fal fa-address-book",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name) +
                                          " (" +
                                          _vm._s(option.email) +
                                          _vm._s(option.phone) +
                                          ")\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  true
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                option.item_type == "graph"
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "fab fa-windows",
                                      }),
                                      _vm._v(" " + _vm._s(option.name) + " ("),
                                      option.email
                                        ? _c("span", [
                                            _vm._v(_vm._s(option.email)),
                                          ])
                                        : _vm._e(),
                                      option.phone
                                        ? _c("span", [
                                            _vm._v(_vm._s(option.phone)),
                                          ])
                                        : _vm._e(),
                                      _vm._v(")\n                "),
                                    ])
                                  : _vm._e(),
                                option.item_type == "message-link"
                                  ? _c("div", [
                                      _vm.isEmail(option.email)
                                        ? _c("i", {
                                            staticClass:
                                              "fal fa-up-right-from-square",
                                          })
                                        : _vm._e(),
                                      _vm.isPhone(option.email)
                                        ? _c("i", {
                                            staticClass: "fal fa-phone",
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(option.email) +
                                          "\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  false
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                option.item_type == "message-user"
                                  ? _c("div", [
                                      _c("img", {
                                        staticClass: "icon mr",
                                        attrs: {
                                          src: "/img/message_type/message.png",
                                          alt: _vm.$t("SEFOS_USER"),
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(option.email) +
                                          "\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  false
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                option.item_type == "user"
                                  ? _c("div", [
                                      _c("img", {
                                        staticClass: "icon mr",
                                        attrs: {
                                          src: "/img/message_type/message.png",
                                          alt: _vm.$t("SEFOS_USER"),
                                        },
                                      }),
                                      _vm._v(" " + _vm._s(option.name) + ", "),
                                      option.title
                                        ? _c("span", [
                                            _vm._v(_vm._s(option.title) + ", "),
                                          ])
                                        : _vm._e(),
                                      option.department
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(option.department) + ", "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(option.organisation) +
                                          ", (" +
                                          _vm._s(option.email) +
                                          ")\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  false
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                option.item_type == "functionbox"
                                  ? _c("div", [
                                      !_vm.IsMeeting(_vm.message_type)
                                        ? _c("img", {
                                            staticClass: "icon mr",
                                            attrs: {
                                              src: "/img/message_type/message.png",
                                              alt: _vm.$t("FUNCTIONBOX.TITLE"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name) +
                                          "," +
                                          _vm._s(option.organisation) +
                                          " (" +
                                          _vm._s(option.email) +
                                          ")\n                  "
                                      ),
                                      _vm.canBlacklist
                                        ? _c("i", {
                                            staticClass:
                                              "float-right fal fa-times",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.blacklistItem(
                                                  option.email,
                                                  false
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _vm.autocompleteResult.length > this.limit
                          ? _c(
                              "b-list-group-item",
                              { staticClass: "m-0 p-0 mt-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-left",
                                    attrs: { size: "sm" },
                                    on: { click: _vm.clearAndFocusSearch },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                ),
                                _c("b-pagination", {
                                  attrs: {
                                    "total-rows": _vm.autocompleteResult.length,
                                    "per-page": _vm.limit,
                                    pills: "",
                                    align: "center",
                                  },
                                  model: {
                                    value: _vm.page,
                                    callback: function ($$v) {
                                      _vm.page = $$v
                                    },
                                    expression: "page",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }